import React from 'react';

const Logo = ({ className = '' }) => (
  <svg className={`w-8 h-8 ${className}`} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="6" fill="url(#paint0_linear)" />
    <path d="M8 8L24 24M8 24L24 8" stroke="white" strokeWidth="3" strokeLinecap="round" />
    <defs>
      <linearGradient id="paint0_linear" x1="0" y1="0" x2="32" y2="32" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4F46E5" />
        <stop offset="1" stopColor="#7C3AED" />
      </linearGradient>
    </defs>
  </svg>
);

export default Logo;