import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { FaChartBar, FaUsers, FaGlobe, FaEye } from 'react-icons/fa';
import { API_URL } from '../../config';

ChartJS.register(...registerables);

const AnalyticsPage = () => {
  const [analyticsData, setAnalyticsData] = useState(null);
  const [pastes, setPastes] = useState([]);
  const [selectedPaste, setSelectedPaste] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user, getAuthenticatedAxios } = useAuth();

  useEffect(() => {
    if (!user) {
      console.log('User not authenticated'); // Add this line
      setError('Please log in to view analytics');
      return;
    }

    const fetchPastes = async () => {
      try {
        const axios = getAuthenticatedAxios();
        console.log('Fetching pastes...'); // Add this line
        const response = await axios.get(`${API_URL}/api/pastes`);
        console.log('Pastes response:', response.data); // Add this line
        setPastes(response.data);
        if (response.data.length > 0) {
          setSelectedPaste(response.data[0]._id);
        }
      } catch (error) {
        console.error('Error fetching pastes:', error.response || error); // Modified this line
        setError('Failed to fetch pastes. Please try again later.');
      }
    };

    fetchPastes();
  }, [user, getAuthenticatedAxios]);

  useEffect(() => {
    const fetchAnalytics = async () => {
      if (!selectedPaste) return;

      try {
        setLoading(true);
        const axios = getAuthenticatedAxios();
        const response = await axios.get(`/api/analytics/${selectedPaste}`);
        setAnalyticsData(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching analytics:', error);
        setError('Failed to fetch analytics data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchAnalytics();
  }, [getAuthenticatedAxios, selectedPaste]);

  if (error) {
    return <div className="text-red-500 text-center mt-8">{error}</div>;
  }

  const prepareChartData = (data, label, dataKey) => {
    if (!data || data.length === 0) return null;

    const sortedData = [...data].sort((a, b) => new Date(a.date) - new Date(b.date));
    return {
      labels: sortedData.map(item => new Date(item.date).toLocaleDateString()),
      datasets: [{
        label: label,
        data: sortedData.map(item => item[dataKey]),
        fill: false,
        borderColor: label === 'Views' ? 'rgb(255, 99, 132)' : 'rgb(75, 192, 192)',
        tension: 0.1
      }]
    };
  };

  const viewsOverTimeData = analyticsData ? prepareChartData(analyticsData.viewsOverTime, 'Views', 'count') : null;
  const uniqueVisitorsData = analyticsData ? prepareChartData(analyticsData.viewsOverTime, 'Unique Visitors', 'uniqueVisitors') : null;

  const topCountriesData = analyticsData && analyticsData.topCountries.length > 0 ? {
    labels: analyticsData.topCountries.map(country => country.name),
    datasets: [{
      label: 'Views by Country',
      data: analyticsData.topCountries.map(country => country.count),
      backgroundColor: 'rgba(54, 162, 235, 0.6)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 1
    }]
  } : null;

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-800 to-gray-900 py-4 sm:py-6 md:py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-2xl sm:text-3xl font-extrabold text-white mb-4 sm:mb-6">Analytics Dashboard</h1>
        
        <div className="mb-4 sm:mb-6">
          <label htmlFor="paste-select" className="block text-sm font-medium text-gray-300 mb-2">
            Select a Paste
          </label>
          <select
            id="paste-select"
            value={selectedPaste}
            onChange={(e) => setSelectedPaste(e.target.value)}
            className="block w-full bg-gray-700 border border-gray-600 rounded-md py-2 px-3 text-white"
          >
            {pastes.map((paste) => (
              <option key={paste._id} value={paste._id}>
                {paste.title}
              </option>
            ))}
          </select>
        </div>

        {loading && <div className="text-white text-center mt-4">Loading analytics...</div>}

        {!loading && analyticsData && (
          <>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4 sm:mb-6">
              <div className="bg-gray-800 rounded-lg shadow-xl p-4">
                <div className="flex items-center">
                  <FaEye className="text-4xl text-blue-500 mr-4" />
                  <div>
                    <p className="text-sm text-gray-400">Total Views</p>
                    <p className="text-2xl font-bold text-white">{analyticsData.totalViews}</p>
                  </div>
                </div>
              </div>
              <div className="bg-gray-800 rounded-lg shadow-xl p-4">
                <div className="flex items-center">
                  <FaUsers className="text-4xl text-green-500 mr-4" />
                  <div>
                    <p className="text-sm text-gray-400">Unique Visitors</p>
                    <p className="text-2xl font-bold text-white">{analyticsData.uniqueVisitors}</p>
                  </div>
                </div>
              </div>
              <div className="bg-gray-800 rounded-lg shadow-xl p-4">
                <div className="flex items-center">
                  <FaGlobe className="text-4xl text-yellow-500 mr-4" />
                  <div>
                    <p className="text-sm text-gray-400">Countries</p>
                    <p className="text-2xl font-bold text-white">{analyticsData.totalCountries}</p>
                  </div>
                </div>
              </div>
              <div className="bg-gray-800 rounded-lg shadow-xl p-4">
                <div className="flex items-center">
                  <FaChartBar className="text-4xl text-purple-500 mr-4" />
                  <div>
                    <p className="text-sm text-gray-400">Avg. Views per Paste</p>
                    <p className="text-2xl font-bold text-white">{analyticsData.avgViewsPerPaste.toFixed(2)}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6 mb-4 sm:mb-6">
              <div className="bg-gray-800 rounded-lg shadow-xl p-4">
                <h2 className="text-lg sm:text-xl font-bold text-white mb-4">Views Over Time</h2>
                <div style={{ height: '300px' }}>
                  {viewsOverTimeData ? (
                    <Line data={viewsOverTimeData} options={{ responsive: true, maintainAspectRatio: false }} />
                  ) : (
                    <p className="text-white text-center">No data available for views over time.</p>
                  )}
                </div>
              </div>
              <div className="bg-gray-800 rounded-lg shadow-xl p-4">
                <h2 className="text-lg sm:text-xl font-bold text-white mb-4">Unique Visitors Over Time</h2>
                <div style={{ height: '300px' }}>
                  {uniqueVisitorsData ? (
                    <Line data={uniqueVisitorsData} options={{ responsive: true, maintainAspectRatio: false }} />
                  ) : (
                    <p className="text-white text-center">No data available for unique visitors over time.</p>
                  )}
                </div>
              </div>
            </div>

            <div className="bg-gray-800 rounded-lg shadow-xl p-4 mb-4 sm:mb-6">
              <h2 className="text-lg sm:text-xl font-bold text-white mb-4">Top Countries</h2>
              <div style={{ height: '300px' }}>
                {topCountriesData ? (
                  <Bar 
                    data={topCountriesData} 
                    options={{ 
                      responsive: true, 
                      maintainAspectRatio: false,
                      scales: {
                        y: {
                          beginAtZero: true,
                          ticks: {
                            color: 'white'
                          }
                        },
                        x: {
                          ticks: {
                            color: 'white'
                          }
                        }
                      },
                      plugins: {
                        legend: {
                          labels: {
                            color: 'white'
                          }
                        }
                      }
                    }} 
                  />
                ) : (
                  <p className="text-white text-center">No data available for top countries.</p>
                )}
              </div>
            </div>

            <div className="bg-gray-800 rounded-lg shadow-xl p-4">
              <h2 className="text-lg sm:text-xl font-bold text-white mb-4">Top Countries List</h2>
              <ul className="list-disc list-inside text-white">
                {analyticsData.topCountries.map((country, index) => (
                  <li key={index} className="mb-2">
                    {country.name}: {country.count} views
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AnalyticsPage;