import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaCode, FaLock, FaShare, FaClock, FaUserFriends, FaSearch, FaMobileAlt, FaChartLine, FaGithub, FaTwitter, FaLinkedin, FaShieldAlt, FaChartBar, FaEnvelope, FaFileAlt, FaFileCode, FaTable, FaTerminal, FaRocket, FaGlobe } from 'react-icons/fa';
import Logo from './Logo';
import CookieConsentDialog from './common/CookieConsentDialog';
import '../styles/LandingPage.css';

const LandingPage = () => {
  const heroRef = useRef(null);
  const featuresRef = useRef(null);
  const encryptionAnalyticsRef = useRef(null);
  const advantagesRef = useRef(null);
  const ctaRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('.section');
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        const isVisible = (rect.top <= window.innerHeight * 0.75) && (rect.bottom >= 0);
        section.classList.toggle('visible', isVisible);
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToRef = (ref) => {
    const offset = 80; // Adjust this value based on your header height
    const elementPosition = ref.current.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  };

  const features = [
    { icon: <FaCode />, title: 'Code Snippets', description: 'Share and collaborate on code from any programming language' },
    { icon: <FaFileAlt />, title: 'Text & Markup', description: 'Share plain text, HTML, XML, Markdown, and more' },
    { icon: <FaTable />, title: 'Structured Data', description: 'Share CSV, JSON, YAML, and other data formats' },
    { icon: <FaLock />, title: 'Private Pastes', description: 'Securely share your content with selected users' },
    { icon: <FaShare />, title: 'Easy Sharing', description: 'Generate shareable links for your pastes' },
    { icon: <FaClock />, title: 'Expiration Settings', description: 'Set custom expiration times for your pastes' },
    { icon: <FaUserFriends />, title: 'Collaboration', description: 'Invite others to view and edit your pastes' },
    { icon: <FaSearch />, title: 'Search Functionality', description: 'Quickly find your pastes with powerful search' },
    { icon: <FaShieldAlt />, title: 'Advanced Encryption', description: 'AES, DES, and RC4 encryption for private pastes' },
    { icon: <FaChartBar />, title: 'Detailed Analytics', description: 'Track views, visitors, and performance' },
  ];

  const advantages = [
    'Effortless sharing of code snippets and text content',
    'Enhanced collaboration with team members and clients',
    'Improved code organization and version tracking',
    'Accessible from any device with internet connectivity',
    'Secure storage for sensitive information with privacy controls',
    'Multiple encryption options for private pastes',
    'Comprehensive analytics to track paste performance',
  ];

  const otherServicesDisadvantages = [
    'Limited paste size and feature restrictions in free tiers',
    'Complex interfaces that can be difficult to navigate',
    'Basic or inadequate security measures for sensitive data',
    'Unreliable service with frequent downtime',
    'Infrequent updates and lack of new feature implementations',
    'Limited or non-existent customer support for free users',
  ];

  const heroPoints = [
    { icon: FaCode, title: "No Character Limits", description: "Unlike Slack, Twitter, or Discord" },
    { icon: FaLock, title: "End-to-End Encryption", description: "Safer than email or public gists" },
    { icon: FaClock, title: "Custom Expiration", description: "Control not found on most platforms" },
    { icon: FaUserFriends, title: "Real-time Collaboration", description: "Better than basic text editors" }
  ];

  return (
    <div className="landing-page-wrapper full-width-container">
      {/* Hero Section */}
      <section ref={heroRef} className="landing-section hero-section relative overflow-hidden bg-indigo-700">
        <div className="section-content relative z-10">
          <div className="text-center">
            <div className="hero-logo-container mb-8 animate-fade-in-down">
              <Logo className="logo" />
              <h1 className="text-5xl sm:text-6xl md:text-7xl font-extrabold text-white">
                Paste<span className="text-indigo-300">Zen</span>
              </h1>
            </div>
            <p className="mt-3 max-w-md mx-auto text-xl sm:text-2xl md:mt-5 md:max-w-3xl text-white animate-fade-in-up">
              Share Code and Text Securely, Without Platform Limits
            </p>
            <div className="mt-10 grid grid-cols-2 gap-6 max-w-2xl mx-auto">
              {heroPoints.map((point, index) => (
                <div key={index} className="animate-fade-in flex items-center bg-indigo-600 rounded-lg p-4 shadow-lg hover:bg-indigo-500 transition-colors duration-300" style={{animationDelay: `${index * 0.2}s`}}>
                  <point.icon className="text-indigo-300 text-3xl mr-4 flex-shrink-0" />
                  <div className="text-left">
                    <h3 className="text-white font-semibold text-lg">{point.title}</h3>
                    <p className="text-indigo-100 text-sm">{point.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-10 flex justify-center space-x-6 animate-fade-in-up" style={{animationDelay: '0.8s'}}>
              <Link
                to="/register"
                className="px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-white hover:bg-indigo-50 md:py-4 md:text-lg md:px-10 transition duration-300 transform hover:scale-105"
              >
                Signup
              </Link>
              <Link
                to="/login"
                className="px-8 py-3 border border-white text-base font-medium rounded-md text-white hover:bg-indigo-500 md:py-4 md:text-lg md:px-10 transition duration-300 transform hover:scale-105"
              >
                Sign In
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section ref={featuresRef} className="landing-section features-section">
        <div className="section-content">
          <h2 className="text-3xl sm:text-4xl font-bold mb-12 text-center bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-500">
            Share Any Content, Securely
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="feature-card bg-gray-700 rounded-lg p-6 hover:bg-gray-600">
                <div className="text-indigo-500 text-3xl mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-300">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Encryption and Analytics Section */}
      <section ref={encryptionAnalyticsRef} className="landing-section encryption-analytics-section">
        <div className="section-content">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div className="encryption-analytics-card rounded-lg p-8">
              <h2 className="text-3xl font-bold mb-6 text-indigo-400">Advanced Encryption</h2>
              <p className="text-gray-300 mb-4">Protect your private pastes with industry-standard encryption algorithms:</p>
              <ul className="space-y-2 text-gray-300">
                <li><FaShieldAlt className="inline mr-2 text-green-500" /> AES (Advanced Encryption Standard)</li>
                <li><FaShieldAlt className="inline mr-2 text-green-500" /> DES (Data Encryption Standard)</li>
                <li><FaShieldAlt className="inline mr-2 text-green-500" /> RC4 (Rivest Cipher 4)</li>
              </ul>
            </div>
            <div className="encryption-analytics-card rounded-lg p-8">
              <h2 className="text-3xl font-bold mb-6 text-indigo-400">Comprehensive Analytics</h2>
              <p className="text-gray-300 mb-4">Gain insights into your paste performance with:</p>
              <ul className="space-y-2 text-gray-300">
                <li><FaChartBar className="inline mr-2 text-blue-500" /> Total views and unique visitors</li>
                <li><FaChartBar className="inline mr-2 text-blue-500" /> Top countries of visitors</li>
                <li><FaChartBar className="inline mr-2 text-blue-500" /> Time-based view statistics</li>
                <li><FaChartBar className="inline mr-2 text-blue-500" /> Detailed visitor information</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Advantages and Other Services Disadvantages Section */}
      <section ref={advantagesRef} className="landing-section advantages-section">
        <div className="section-content">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div className="bg-gray-700 rounded-lg p-8 transform hover:scale-105 transition duration-300">
              <h2 className="text-3xl font-bold mb-6 text-indigo-400">Why Choose PasteZen</h2>
              <ul className="space-y-4">
                {advantages.map((advantage, index) => (
                  <li key={index} className="flex items-center">
                    <svg className="h-6 w-6 text-green-500 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>
            <div className="bg-gray-700 rounded-lg p-8 transform hover:scale-105 transition duration-300">
              <h2 className="text-3xl font-bold mb-6 text-indigo-400">Disadvantages of Other Services</h2>
              <ul className="space-y-4">
                {otherServicesDisadvantages.map((disadvantage, index) => (
                  <li key={index} className="flex items-center">
                    <svg className="h-6 w-6 text-red-500 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section ref={ctaRef} className="landing-section cta-section">
        <div className="section-content text-center">
          <h2 className="text-3xl sm:text-4xl font-bold mb-4">Ready to experience secure and insightful paste sharing?</h2>
          <p className="text-xl mb-8">Join PasteZen today and elevate your content sharing experience.</p>
          <Link
            to="/register"
            className="inline-block bg-white text-indigo-600 font-bold py-3 px-8 rounded-lg hover:bg-gray-100 transition duration-300 transform hover:scale-105"
          >
            Sign Up Now
          </Link>
        </div>
      </section>

      {/* Footer */}
      <footer className="landing-section footer-section">
        <div className="section-content">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-2xl font-bold mb-4 flex items-center">
                <Logo className="logo mr-2" />
                PasteZen
              </h3>
              <p className="mb-4">Secure, insightful content sharing for everyone.</p>
              <div className="flex space-x-4">
                <a href="#" className="text-gray-400 hover:text-white transition duration-300">
                  <FaGithub size={24} />
                </a>
                <a href="#" className="text-gray-400 hover:text-white transition duration-300">
                  <FaTwitter size={24} />
                </a>
                <a href="#" className="text-gray-400 hover:text-white transition duration-300">
                  <FaLinkedin size={24} />
                </a>
              </div>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
              <ul className="space-y-2">
                <li><button onClick={() => scrollToRef(heroRef)} className="hover:text-white transition duration-300">Home</button></li>
                <li><button onClick={() => scrollToRef(featuresRef)} className="hover:text-white transition duration-300">Features</button></li>
                <li><button onClick={() => scrollToRef(encryptionAnalyticsRef)} className="hover:text-white transition duration-300">Security & Analytics</button></li>
                <li><button onClick={() => scrollToRef(advantagesRef)} className="hover:text-white transition duration-300">Advantages</button></li>
                <li><button onClick={() => scrollToRef(ctaRef)} className="hover:text-white transition duration-300">Get Started</button></li>
                <li><a href="https://tally.so/r/31v7vg" target="_blank" rel="noopener noreferrer" className="hover:text-white transition duration-300 flex items-center">
                  <FaEnvelope className="mr-2" /> Contact Us
                </a></li>
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Paste Types</h4>
              <ul className="space-y-2">
                <li><span className="flex items-center"><FaCode className="mr-2" /> Code</span></li>
                <li><span className="flex items-center"><FaShare className="mr-2" /> Text</span></li>
                <li><span className="flex items-center"><FaLock className="mr-2" /> Private (Encrypted)</span></li>
                <li><span className="flex items-center"><FaClock className="mr-2" /> Timed</span></li>
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Legal</h4>
              <ul className="space-y-2">
                <li><Link to="/terms" className="hover:text-white transition duration-300">Terms of Service</Link></li>
                <li><Link to="/privacy" className="hover:text-white transition duration-300">Privacy Policy</Link></li>
                <li><Link to="/cookies" className="hover:text-white transition duration-300">Cookie Policy</Link></li>
              </ul>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-700 text-center">
            <p>&copy; 2023 PasteZen. All rights reserved.</p>
          </div>
        </div>
      </footer>

      {/* Add the CookieConsentDialog component */}
      <CookieConsentDialog />
    </div>
  );
};

export default LandingPage;