import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import Logo from '../Logo';
import { FaChartBar, FaBars, FaTimes, FaUser, FaSignOutAlt, FaClipboard, FaPlus, FaTachometerAlt, FaSignInAlt, FaUserPlus, FaEnvelope } from 'react-icons/fa';

const Navbar = () => {
  const { user, logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="bg-gray-800 shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center" onClick={closeMenu}>
              <Logo className="h-8 w-8 mr-2" />
              <span className="text-white font-bold text-xl">PasteZen</span>
            </Link>
          </div>
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4">
              {user ? (
                <>
                  <NavLink to="/dashboard" icon={<FaTachometerAlt />} onClick={closeMenu}>Dashboard</NavLink>
                  <NavLink to="/pastes" icon={<FaClipboard />} onClick={closeMenu}>My Pastes</NavLink>
                  <NavLink to="/create" icon={<FaPlus />} onClick={closeMenu}>Create Paste</NavLink>
                  <NavLink to="/analytics" icon={<FaChartBar />} onClick={closeMenu}>Analytics</NavLink>
                  <button onClick={logout} className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium flex items-center">
                    <FaSignOutAlt className="mr-2" /> Logout
                  </button>
                </>
              ) : (
                <>
                  <NavLink to="/login" icon={<FaSignInAlt />} onClick={closeMenu}>Login</NavLink>
                  <NavLink to="/register" icon={<FaUserPlus />} onClick={closeMenu}>Register</NavLink>
                  <NavLink href="https://tally.so/r/31v7vg" icon={<FaEnvelope />} onClick={closeMenu} external>Contact Us</NavLink>
                </>
              )}
            </div>
          </div>
          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            >
              {isMenuOpen ? <FaTimes /> : <FaBars />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`${isMenuOpen ? 'block' : 'hidden'} md:hidden`}>
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          {user ? (
            <>
              <MobileNavLink to="/dashboard" icon={<FaTachometerAlt />} onClick={closeMenu}>Dashboard</MobileNavLink>
              <MobileNavLink to="/pastes" icon={<FaClipboard />} onClick={closeMenu}>My Pastes</MobileNavLink>
              <MobileNavLink to="/create" icon={<FaPlus />} onClick={closeMenu}>Create Paste</MobileNavLink>
              <MobileNavLink to="/analytics" icon={<FaChartBar />} onClick={closeMenu}>Analytics</MobileNavLink>
              <button
                onClick={() => { logout(); closeMenu(); }}
                className="flex items-center w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700"
              >
                <FaSignOutAlt className="mr-3" /> Logout
              </button>
            </>
          ) : (
            <>
              <MobileNavLink to="/login" icon={<FaSignInAlt />} onClick={closeMenu}>Login</MobileNavLink>
              <MobileNavLink to="/register" icon={<FaUserPlus />} onClick={closeMenu}>Register</MobileNavLink>
              <MobileNavLink href="https://tally.so/r/31v7vg" icon={<FaEnvelope />} onClick={closeMenu} external>Contact Us</MobileNavLink>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

const NavLink = ({ to, href, icon, children, external }) => {
  const LinkComponent = external ? 'a' : Link;
  const linkProps = external ? { href, target: "_blank", rel: "noopener noreferrer" } : { to };

  return (
    <LinkComponent
      {...linkProps}
      className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium flex items-center"
    >
      {icon && <span className="mr-2">{icon}</span>}
      {children}
    </LinkComponent>
  );
};

const MobileNavLink = ({ to, href, icon, children, onClick, external }) => {
  const LinkComponent = external ? 'a' : Link;
  const linkProps = external ? { href, target: "_blank", rel: "noopener noreferrer" } : { to };

  return (
    <LinkComponent
      {...linkProps}
      className="flex items-center px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700"
      onClick={onClick}
    >
      {icon && <span className="mr-3">{icon}</span>}
      {children}
    </LinkComponent>
  );
};

export default Navbar;