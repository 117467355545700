import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import useApi from '../hooks/useApi';
import { API_URL } from '../config';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const api = useApi();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken && decodedToken.user) {
          setUser({ id: decodedToken.user.id, email: decodedToken.user.email });
        } else {
          // If the token is invalid, remove it
          localStorage.removeItem('token');
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        localStorage.removeItem('token');
      }
    }
  }, []);

  const login = async (email, password) => {
    try {
      const response = await api.post('/api/users/login', { email, password });
      const { token } = response.data;
      localStorage.setItem('token', token);
      console.log('Token stored:', token); // Add this line
      const decodedToken = jwtDecode(token);
      if (decodedToken && decodedToken.user) {
        setUser({ id: decodedToken.user.id, email: decodedToken.user.email });
      }
      return response.data;
    } catch (error) {
      console.error('Login error:', error);
      throw error.response ? error.response.data : error.message;
    }
  };

  const register = async (email, password) => {
    try {
      const response = await api.post('/api/users/register', { email, password });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  };

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    setUser(null);
  }, []);

  const getAuthenticatedAxios = useCallback(() => {
    const token = localStorage.getItem('token');
    console.log('Token being used:', token); // Add this line
    return axios.create({
      baseURL: API_URL,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token ? `Bearer ${token}` : ''
      }
    });
  }, []);

  const value = {
    user,
    login,
    register,
    logout,
    getAuthenticatedAxios
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

// Remove this line as AuthProvider is already exported above
// export { AuthContext, AuthProvider };