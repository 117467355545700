import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-hot-toast';
import { FaUser, FaClock, FaPaperPlane } from 'react-icons/fa';
import { API_URL } from '../../config';

function CommentSection({ pasteId, comments, isAuthenticated, onCommentAdded }) {
  const [newComment, setNewComment] = useState('');
  const { getAuthenticatedAxios, user } = useAuth();

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!newComment.trim()) return;

    try {
      const authenticatedAxios = getAuthenticatedAxios();
      const response = await authenticatedAxios.post(`${API_URL}/api/comments/${pasteId}`, {
        content: newComment
      });
      if (response.data) {
        onCommentAdded(response.data);
        setNewComment('');
        toast.success('Comment posted successfully!');
      } else {
        throw new Error('No data returned from comment creation');
      }
    } catch (error) {
      console.error('Error posting comment:', error);
      toast.error('Failed to post comment. Please try again.');
    }
  };

  return (
    <div className="bg-gray-800 rounded-lg shadow-xl overflow-hidden">
      <div className="px-6 py-4 bg-indigo-600 bg-opacity-75">
        <h3 className="text-xl font-semibold text-white">Comments</h3>
      </div>
      <div className="px-6 py-4">
        {comments && comments.length > 0 ? (
          <ul className="space-y-4 max-h-96 overflow-y-auto">
            {comments.map((comment) => (
              <li key={comment._id} className="bg-gray-700 rounded-lg p-4">
                <div className="flex items-center justify-between mb-2">
                  <span className="flex items-center text-gray-300 text-sm">
                    <FaUser className="mr-2" />
                    {comment.author ? comment.author.email : 'Anonymous'}
                  </span>
                  <span className="flex items-center text-gray-400 text-xs">
                    <FaClock className="mr-1" />
                    {new Date(comment.createdAt).toLocaleString()}
                  </span>
                </div>
                <p className="text-gray-200">{comment.content}</p>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-400">No comments yet. Be the first to comment!</p>
        )}

        {user ? (
          <form onSubmit={handleCommentSubmit} className="mt-6">
            <div className="flex items-center">
              <input
                type="text"
                placeholder="Add a comment..."
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                className="flex-grow px-4 py-2 bg-gray-700 text-white rounded-l-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
              <button
                type="submit"
                className="px-4 py-2 bg-indigo-500 text-white rounded-r-md hover:bg-indigo-600 transition duration-300 flex items-center"
              >
                <FaPaperPlane className="mr-2" />
                Post
              </button>
            </div>
          </form>
        ) : (
          <p className="text-gray-400 mt-4">Please log in to leave a comment.</p>
        )}
      </div>
    </div>
  );
}

export default CommentSection;