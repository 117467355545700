import React from 'react';
import { Link } from 'react-router-dom';

const EmailVerificationFailed = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900 px-4 sm:px-6 lg:px-8 absolute inset-0">
      <div className="w-full max-w-md">
        <div className="bg-gray-800 rounded-lg shadow-md p-6 sm:p-8">
          <h2 className="mb-6 text-2xl sm:text-3xl font-bold text-center text-white">Email Verification Failed</h2>
          <p className="text-gray-300 mb-6 text-center">
            We couldn't verify your email. The verification link may have expired or is invalid.
          </p>
          <div className="mt-6">
            <Link
              to="/register"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Try Registering Again
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationFailed;