import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { FaCode, FaEye, FaClock, FaLock, FaPlus, FaUserPlus, FaTrash, FaSearch } from 'react-icons/fa';
import { API_URL } from '../../config';
import ConfirmDialog from '../common/ConfirmDialog';

const PasteList = () => {
  const [pastes, setPastes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const { getAuthenticatedAxios } = useAuth();
  const user = useAuth().user;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [pasteToDelete, setPasteToDelete] = useState(null);

  useEffect(() => {
    fetchPastes();
  }, [getAuthenticatedAxios]);

  const fetchPastes = async () => {
    try {
      const axios = getAuthenticatedAxios();
      const response = await axios.get(API_URL +'/api/pastes/my');
      setPastes(response.data);
      
      const uniqueCategories = ['all', ...new Set(response.data.map(paste => paste.category || 'uncategorized'))];
      setCategories(uniqueCategories);
    } catch (error) {
      console.error('Error fetching pastes:', error);
      toast.error('Failed to fetch pastes. Please try again.');
    }
  };

  const handleDelete = async (pasteId) => {
    if (window.confirm('Are you sure you want to delete this paste?')) {
      try {
        const axios = getAuthenticatedAxios();
        await axios.delete(`${API_URL}/api/pastes/${pasteId}`);
        toast.success('Paste deleted successfully');
        fetchPastes(); // Refresh the list after deletion
      } catch (error) {
        console.error('Error deleting paste:', error);
        toast.error('Failed to delete paste');
      }
    }
  };

  const openDeleteDialog = (paste) => {
    setPasteToDelete(paste);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setPasteToDelete(null);
  };

  const confirmDelete = async () => {
    if (pasteToDelete) {
      try {
        const axios = getAuthenticatedAxios();
        await axios.delete(`${API_URL}/api/pastes/${pasteToDelete._id}`);
        toast.success('Paste deleted successfully');
        fetchPastes(); // Refresh the list after deletion
        closeDeleteDialog();
      } catch (error) {
        console.error('Error deleting paste:', error);
        toast.error('Failed to delete paste');
      }
    }
  };

  const filteredPastes = pastes
    .filter(paste => activeCategory === 'all' || (paste.category || 'uncategorized') === activeCategory)
    .filter(paste => 
      paste.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      paste.content.toLowerCase().includes(searchTerm.toLowerCase())
    );

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-800 to-gray-900 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="bg-gray-800 rounded-lg shadow-xl overflow-hidden">
          <div className="px-8 py-6 bg-indigo-600 bg-opacity-75 flex justify-between items-center">
            <h2 className="text-3xl font-extrabold text-white">My Pastes</h2>
            <Link
              to="/create"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <FaPlus className="mr-2" />
              Create New Paste
            </Link>
          </div>
          <div className="px-8 py-6">
            <div className="mb-6 flex flex-col sm:flex-row justify-between items-center">
              <div className="flex space-x-4 mb-4 sm:mb-0 overflow-x-auto">
                {categories.map((category) => (
                  <button
                    key={category}
                    onClick={() => setActiveCategory(category)}
                    className={`${
                      activeCategory === category
                        ? 'bg-indigo-500 text-white'
                        : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                    } px-3 py-1 rounded-full text-sm font-medium transition-colors duration-200`}
                  >
                    {category.charAt(0).toUpperCase() + category.slice(1)}
                  </button>
                ))}
              </div>
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search pastes..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full sm:w-64 px-4 py-2 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 pl-10"
                />
                <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            </div>
            {filteredPastes.length === 0 ? (
              <div className="text-center py-12">
                <FaCode className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-medium text-gray-300">No pastes found</h3>
                <p className="mt-1 text-sm text-gray-500">Get started by creating a new paste.</p>
                <div className="mt-6">
                  <Link
                    to="/create"
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <FaPlus className="mr-2" />
                    Create Paste
                  </Link>
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {filteredPastes.map((paste) => (
                  <div key={paste._id} className="bg-gray-700 rounded-lg shadow-md p-6 hover:bg-gray-600 transition duration-300 transform hover:-translate-y-1 hover:scale-105">
                    <Link to={`/pastes/${paste._id}`}>
                      <h3 className="text-xl font-semibold text-white mb-2 truncate">{paste.title}</h3>
                      <div className="flex items-center text-gray-300 mb-2">
                        <FaCode className="mr-2" />
                        <span className="bg-gray-800 px-2 py-1 rounded-md text-xs">{paste.language}</span>
                      </div>
                      <div className="flex items-center text-gray-300 mb-2">
                        <FaClock className="mr-2" />
                        <span className="text-sm">{new Date(paste.createdAt).toLocaleString()}</span>
                      </div>
                      <div className="flex items-center text-gray-300">
                        {paste.visibility === 'private' ? (
                          <span className="bg-red-500 text-white text-xs px-2 py-1 rounded-full flex items-center">
                            <FaLock className="mr-1" />
                            Private
                          </span>
                        ) : (
                          <span className="bg-green-500 text-white text-xs px-2 py-1 rounded-full flex items-center">
                            <FaEye className="mr-1" />
                            Public
                          </span>
                        )}
                      </div>
                    </Link>
                    <div className="mt-4 flex justify-between items-center">
                      {paste.invitedUsers && paste.invitedUsers.includes(user.id) && (
                        <span className="bg-indigo-500 text-white text-xs px-2 py-1 rounded-full flex items-center">
                          <FaUserPlus className="mr-1" />
                          Invited
                        </span>
                      )}
                      <button
                        onClick={() => openDeleteDialog(paste)}
                        className="bg-red-500 hover:bg-red-600 text-white px-2 py-1 rounded-md text-sm flex items-center"
                      >
                        <FaTrash className="mr-1" />
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <ConfirmDialog
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        onConfirm={confirmDelete}
        title="Delete Paste"
        message="Are you sure you want to delete this paste? This action cannot be undone."
      />
    </div>
  );
};

export default PasteList;