import React from 'react';
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-800 to-gray-900 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-gray-700 rounded-lg shadow-xl p-8">
        <h1 className="text-3xl font-bold text-white mb-6">Terms of Service</h1>
        <div className="space-y-4 text-gray-300">
          <p>Welcome to PasteZen. By using our service, you agree to these terms.</p>
          
          <h2 className="text-xl font-semibold text-white mt-4">1. Use of Service</h2>
          <p>PasteZen provides a platform for sharing and storing text and code snippets. You are responsible for your use of the service and any content you post.</p>
          
          <h2 className="text-xl font-semibold text-white mt-4">2. Account Registration</h2>
          <p>You may need to create an account to use some features. You are responsible for maintaining the security of your account.</p>
          
          <h2 className="text-xl font-semibold text-white mt-4">3. Content</h2>
          <p>You retain ownership of the content you post. By posting, you grant PasteZen a license to use, store, and share your content.</p>
          
          <h2 className="text-xl font-semibold text-white mt-4">4. Prohibited Activities</h2>
          <p>You may not use PasteZen for any illegal activities or to violate others' rights.</p>
          
          <h2 className="text-xl font-semibold text-white mt-4">5. Termination</h2>
          <p>We reserve the right to terminate or suspend your account for violations of these terms.</p>
          
          <h2 className="text-xl font-semibold text-white mt-4">6. Changes to Terms</h2>
          <p>We may modify these terms at any time. Continued use of PasteZen constitutes acceptance of the new terms.</p>
          
          <p className="mt-6">For questions about these terms, please <Link to="/contact" className="text-indigo-400 hover:text-indigo-300">contact us</Link>.</p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;