import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Editor } from '@monaco-editor/react';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-hot-toast';
import { languages } from '../../utils/languageOptions';
import { FaPlus, FaCode, FaEye, FaClock, FaLock, FaGlobe, FaUserPlus, FaShieldAlt, FaTag } from 'react-icons/fa';
import { useResizeDetector } from 'react-resize-detector';
import { API_URL } from '../../config';
import { Buffer } from 'buffer';

const CreatePaste = () => {
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [language, setLanguage] = useState('javascript');
  const [visibility, setVisibility] = useState('public');
  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [expiration, setExpiration] = useState('never');
  const [inviteEmails, setInviteEmails] = useState('');
  const [encryptionLevel, setEncryptionLevel] = useState('none');
  const [password, setPassword] = useState('');
  const [isPasswordProtected, setIsPasswordProtected] = useState(false);
  const navigate = useNavigate();
  const { getAuthenticatedAxios } = useAuth();

  const { height, ref } = useResizeDetector();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const axios = getAuthenticatedAxios();
      const response = await axios.get(API_URL + '/api/pastes/categories');
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      toast.error('Failed to fetch categories');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (visibility === 'public' && isPasswordProtected && !password) {
      toast.error('Please set a password for the protected paste');
      return;
    }
    try {
      const axios = getAuthenticatedAxios();
      const pasteData = {
        title: title.trim() || 'Untitled',
        content: Buffer.from(content).toString('base64'),
        language: language || 'plaintext',
        visibility,
        category: category || null,
        expiration: expiration || 'never',
        encryptionLevel: visibility === 'private' ? encryptionLevel : 'none',
        password: (visibility === 'private' && encryptionLevel !== 'none') ? password : undefined,
        isPasswordProtected: visibility === 'public' ? isPasswordProtected : false,
      };

      if (visibility === 'public' && isPasswordProtected) {
        pasteData.password = password;
      }

      const response = await axios.post(API_URL + '/api/pastes', pasteData);

      if (response.data && response.data._id) {
        if (visibility === 'private' && inviteEmails) {
          const emails = inviteEmails.split(',').map(email => email.trim()).filter(Boolean);
          for (const email of emails) {
            await axios.post(API_URL + `/api/pastes/${response.data._id}/invite`, { email });
          }
        }

        toast.success('Paste created successfully!');
        navigate(`/pastes/${response.data._id}`);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error creating paste:', error);
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(`Failed to create paste: ${error.response.data.message}`);
      } else {
        toast.error('Failed to create paste. Please try again.');
      }
    }
  };

  const handleCreateCategory = async () => {
    if (!newCategory.trim()) {
      toast.error('Category name cannot be empty');
      return;
    }
    try {
      const axios = getAuthenticatedAxios();
      const response = await axios.post(API_URL + '/api/pastes/categories', { name: newCategory });
      setCategories([...categories, response.data]);
      setCategory(response.data._id);
      setNewCategory('');
      toast.success('New category created!');
    } catch (error) {
      console.error('Error creating category:', error);
      toast.error('Failed to create category. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-800 to-gray-900 py-6 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold text-white mb-6">Create New Paste</h1>
        
        <div className="flex flex-col md:flex-row bg-gray-800 rounded-lg shadow-xl overflow-hidden">
          {/* Left sidebar with controls */}
          <div className="w-full md:w-1/4 bg-gray-900 p-6 space-y-6 overflow-y-auto" style={{ maxHeight: 'calc(100vh - 200px)' }}>
            <div>
              <label htmlFor="title" className="block text-sm font-medium text-gray-300 mb-1">Title</label>
              <input
                id="title"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Enter paste title"
              />
            </div>
            <div>
              <label htmlFor="language" className="block text-sm font-medium text-gray-300 mb-1">Language</label>
              <select
                id="language"
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                {languages.map((lang) => (
                  <option key={lang.value} value={lang.value}>{lang.label}</option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">Visibility</label>
              <div className="flex space-x-4">
                <button
                  type="button"
                  onClick={() => {
                    setVisibility('public');
                    setIsPasswordProtected(false);
                  }}
                  className={`flex-1 flex items-center justify-center px-3 py-2 rounded-md ${
                    visibility === 'public'
                      ? 'bg-green-500 text-white'
                      : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                  }`}
                >
                  <FaGlobe className="mr-2" /> Public
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setVisibility('private');
                    setIsPasswordProtected(false);
                  }}
                  className={`flex-1 flex items-center justify-center px-3 py-2 rounded-md ${
                    visibility === 'private'
                      ? 'bg-red-500 text-white'
                      : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                  }`}
                >
                  <FaLock className="mr-2" /> Private
                </button>
              </div>
            </div>
            {visibility === 'public' && (
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="passwordProtect"
                  checked={isPasswordProtected}
                  onChange={(e) => setIsPasswordProtected(e.target.checked)}
                  className="mr-2"
                />
                <label htmlFor="passwordProtect" className="text-sm font-medium text-gray-300 flex items-center">
                  <FaLock className="mr-2" /> Password Protect
                </label>
              </div>
            )}
            {visibility === 'public' && isPasswordProtected && (
              <div>
                <label htmlFor="pastePassword" className="block text-sm font-medium text-gray-300 mb-1">Paste Password</label>
                <input
                  id="pastePassword"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  placeholder="Enter paste password"
                />
              </div>
            )}
            <div>
              <label htmlFor="expiration" className="block text-sm font-medium text-gray-300 mb-1">Expiration</label>
              <select
                id="expiration"
                value={expiration}
                onChange={(e) => setExpiration(e.target.value)}
                className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                <option value="never">Never</option>
                <option value="10m">10 Minutes</option>
                <option value="1h">1 Hour</option>
                <option value="1d">1 Day</option>
                <option value="1w">1 Week</option>
                <option value="1m">1 Month</option>
              </select>
            </div>
            <div>
              <label htmlFor="category" className="block text-sm font-medium text-gray-300 mb-1">Category</label>
              <div className="flex space-x-2">
                <select
                  id="category"
                  value={category || ''}
                  onChange={(e) => setCategory(e.target.value || null)}
                  className="flex-grow px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  <option value="">Select Category</option>
                  {categories.map((cat) => (
                    <option key={cat._id} value={cat._id}>{cat.name}</option>
                  ))}
                </select>
                <button
                  type="button"
                  onClick={() => document.getElementById('newCategoryModal').classList.remove('hidden')}
                  className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <FaPlus />
                </button>
              </div>
            </div>
            {visibility === 'private' && (
              <>
                <div>
                  <label htmlFor="encryptionLevel" className="block text-sm font-medium text-gray-300 mb-1">Encryption Level</label>
                  <select
                    id="encryptionLevel"
                    value={encryptionLevel}
                    onChange={(e) => setEncryptionLevel(e.target.value)}
                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  >
                    <option value="none">None</option>
                    <option value="aes">AES</option>
                    <option value="des">DES</option>
                    <option value="rc4">RC4</option>
                  </select>
                </div>
                {encryptionLevel !== 'none' && (
                  <div>
                    <label htmlFor="encryptionPassword" className="block text-sm font-medium text-gray-300 mb-1">Encryption Password</label>
                    <input
                      id="encryptionPassword"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      placeholder="Enter encryption password"
                    />
                  </div>
                )}
                <div>
                  <label htmlFor="inviteEmails" className="block text-sm font-medium text-gray-300 mb-1">Invite Users (comma-separated emails)</label>
                  <input
                    id="inviteEmails"
                    type="text"
                    value={inviteEmails}
                    onChange={(e) => setInviteEmails(e.target.value)}
                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    placeholder="user1@example.com, user2@example.com"
                  />
                </div>
              </>
            )}
          </div>

          {/* Right side with content editor */}
          <div className="w-full md:w-3/4 p-6">
            <form onSubmit={handleSubmit}>
              <div ref={ref} style={{ height: 'calc(100vh - 250px)' }}>
                <Editor
                  height="100%"
                  language={language}
                  value={content}
                  onChange={setContent}
                  theme="vs-dark"
                  options={{
                    minimap: { enabled: false },
                    fontSize: 14,
                    lineNumbers: 'on',
                    roundedSelection: false,
                    scrollBeyondLastLine: false,
                    readOnly: false
                  }}
                />
              </div>
              <div className="mt-6">
                <button
                  type="submit"
                  className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <FaPlus className="mr-2" /> Create Paste
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      
      {/* New Category Modal */}
      <div id="newCategoryModal" className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full hidden">
        <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-gray-800">
          <div className="mt-3 text-center">
            <h3 className="text-lg leading-6 font-medium text-white">Add New Category</h3>
            <div className="mt-2 px-7 py-3">
              <input
                type="text"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Category Name"
              />
            </div>
            <div className="items-center px-4 py-3">
              <button
                onClick={handleCreateCategory}
                className="px-4 py-2 bg-green-600 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
              >
                Add Category
              </button>
            </div>
            <div className="items-center px-4 py-3">
              <button
                onClick={() => document.getElementById('newCategoryModal').classList.add('hidden')}
                className="px-4 py-2 bg-red-600 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePaste;