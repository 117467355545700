import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { FaPlus, FaList, FaChartBar, FaClock, FaLock, FaCode, FaEye } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { API_URL } from '../../config';

const Dashboard = () => {
  const [recentPastes, setRecentPastes] = useState([]);
  const [stats, setStats] = useState({ totalPastes: 0, publicPastes: 0, privatePastes: 0 });
  const { getAuthenticatedAxios } = useAuth();

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const axios = getAuthenticatedAxios();
        const [pastesResponse, statsResponse] = await Promise.all([
          axios.get(API_URL + '/api/pastes/recent'),
          axios.get(API_URL + '/api/pastes/stats')
        ]);
        setRecentPastes(pastesResponse.data);
        setStats(statsResponse.data);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        toast.error('Failed to fetch dashboard data');
      }
    };

    fetchDashboardData();
  }, [getAuthenticatedAxios]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-800 to-gray-900 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold text-white mb-8">Dashboard</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          <DashboardCard title="Total Pastes" value={stats.totalPastes} icon={<FaList />} />
          <DashboardCard title="Public Pastes" value={stats.publicPastes} icon={<FaChartBar />} />
          <DashboardCard title="Private Pastes" value={stats.privatePastes} icon={<FaLock />} />
          <QuickActionCard />
        </div>
        
        <div className="bg-gray-800 rounded-lg shadow-xl overflow-hidden mb-8">
          <div className="px-6 py-4 bg-indigo-600 bg-opacity-75">
            <h2 className="text-xl font-semibold text-white">Recent Pastes</h2>
          </div>
          <div className="p-6">
            {recentPastes.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {recentPastes.map((paste) => (
                  <Link key={paste._id} to={`/pastes/${paste._id}`} className="block">
                    <div className="bg-gray-700 rounded-lg p-4 hover:bg-gray-600 transition duration-300">
                      <h3 className="text-lg font-semibold text-white mb-2 truncate">{paste.title}</h3>
                      <div className="flex items-center text-gray-300 text-sm mb-2">
                        <FaCode className="mr-2" />
                        <span>{paste.language}</span>
                      </div>
                      <div className="flex items-center text-gray-300 text-sm mb-2">
                        <FaClock className="mr-2" />
                        <span>{new Date(paste.createdAt).toLocaleDateString()}</span>
                      </div>
                      <div className="flex items-center text-gray-300 text-sm">
                        {paste.visibility === 'private' ? (
                          <><FaLock className="mr-2" /> Private</>
                        ) : (
                          <><FaEye className="mr-2" /> Public</>
                        )}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            ) : (
              <p className="text-gray-400">No recent pastes found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const DashboardCard = ({ title, value, icon }) => (
  <div className="bg-gray-800 rounded-lg shadow-xl p-6">
    <div className="flex items-center justify-between">
      <div>
        <p className="text-gray-400 text-sm">{title}</p>
        <p className="text-white text-2xl font-semibold mt-1">{value}</p>
      </div>
      <div className="text-indigo-500 text-3xl">
        {icon}
      </div>
    </div>
  </div>
);

const QuickActionCard = () => (
  <div className="bg-gray-800 rounded-lg shadow-xl p-6">
    <h3 className="text-white font-semibold mb-4">Quick Actions</h3>
    <Link
      to="/create"
      className="bg-indigo-600 text-white px-4 py-2 rounded-md flex items-center justify-center hover:bg-indigo-700 transition duration-300"
    >
      <FaPlus className="mr-2" />
      Create New Paste
    </Link>
  </div>
);

export default Dashboard;