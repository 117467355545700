import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-800 to-gray-900 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-gray-700 rounded-lg shadow-xl p-8">
        <h1 className="text-3xl font-bold text-white mb-6">Privacy Policy</h1>
        <div className="space-y-4 text-gray-300">
          <p>At PasteZen, we take your privacy seriously. This policy explains how we collect, use, and protect your personal information.</p>
          
          <h2 className="text-xl font-semibold text-white mt-4">1. Information We Collect</h2>
          <p>We collect information you provide directly, such as account details and paste content. We also collect usage data and analytics information.</p>
          
          <h2 className="text-xl font-semibold text-white mt-4">2. How We Use Your Information</h2>
          <p>We use your information to provide and improve our services, communicate with you, and ensure security.</p>
          
          <h2 className="text-xl font-semibold text-white mt-4">3. Data Security</h2>
          <p>We implement security measures to protect your data, including encryption for private pastes.</p>
          
          <h2 className="text-xl font-semibold text-white mt-4">4. Sharing Your Information</h2>
          <p>We do not sell your personal information. We may share data with service providers or if required by law.</p>
          
          <h2 className="text-xl font-semibold text-white mt-4">5. Your Rights</h2>
          <p>You have the right to access, correct, or delete your personal information.</p>
          
          <h2 className="text-xl font-semibold text-white mt-4">6. Changes to This Policy</h2>
          <p>We may update this policy periodically. We will notify you of any significant changes.</p>
          
          <p className="mt-6">For questions about our privacy practices, please <Link to="/contact" className="text-indigo-400 hover:text-indigo-300">contact us</Link>.</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;