import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-hot-toast';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { FaClock, FaUser, FaEye, FaLock, FaCode, FaCopy, FaShare, FaUserPlus, FaComments, FaChevronLeft, FaChevronRight, FaUnlock, FaTrash } from 'react-icons/fa';
import { debounce } from '../../utils/debounce';
import CommentSection from './CommentSection';
import { API_URL } from '../../config';
import ConfirmDialog from '../common/ConfirmDialog';
import { Buffer } from 'buffer';
import axios from 'axios';

const PasteView = () => {
  const [paste, setPaste] = useState(null);
  const [copied, setCopied] = useState(false);
  const { id, shareId } = useParams();
  const location = useLocation();
  const { getAuthenticatedAxios, user } = useAuth();
  const [inviteEmail, setInviteEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [editorHeight, setEditorHeight] = useState(400);
  const resizeRef = useRef(null);
  const [showComments, setShowComments] = useState(false);
  const [fullComments, setFullComments] = useState([]);
  const [password, setPassword] = useState('');
  const [isDecrypting, setIsDecrypting] = useState(false);
  const [decryptedContent, setDecryptedContent] = useState('');
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [passwordInput, setPasswordInput] = useState('');
  const [isPasswordProtected, setIsPasswordProtected] = useState(false);
  const [isPrivateEncrypted, setIsPrivateEncrypted] = useState(false);
  const [decryptionPassword, setDecryptionPassword] = useState('');
  const [requiresAuth, setRequiresAuth] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [decodedContent, setDecodedContent] = useState('');
  const [userCountry, setUserCountry] = useState('Unknown');

  useEffect(() => {
    if (!id && !shareId) {
      setError('Invalid paste ID');
      return;
    }

    const resizeObserver = new ResizeObserver(
      debounce((entries) => {
        for (let entry of entries) {
          if (entry.target === resizeRef.current) {
            setEditorHeight(entry.contentRect.height);
          }
        }
      }, 100)
    );

    if (resizeRef.current) {
      resizeObserver.observe(resizeRef.current);
    }

    return () => {
      if (resizeRef.current) {
        resizeObserver.unobserve(resizeRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const fetchPaste = async () => {
      try {
        setLoading(true);
        const axios = getAuthenticatedAxios();
        let response;

        if (shareId) {
          response = await axios.get(`${API_URL}/api/pastes/share/${shareId}`);
        } else {
          response = await axios.get(`${API_URL}/api/pastes/${id}`);
        }

        if (response.data.isPasswordProtected) {
          setIsPasswordProtected(true);
          setRequiresAuth(response.data.requiresAuth);
          setShowPasswordDialog(true);
        } else if (response.data.encryptionLevel !== 'none' && response.data.visibility === 'private') {
          setIsPrivateEncrypted(true);
          setShowPasswordDialog(true);
        } else {
          setPaste(response.data);
          if (response.data.encryptionLevel === 'none') {
            setDecryptedContent(Buffer.from(response.data.content, 'base64').toString('utf-8'));
          } else {
            setDecryptedContent(''); // Reset decrypted content for encrypted pastes
          }
        }
        
        setIsOwner(user && user.id === response.data.user);
        setLoading(false);
      } catch (error) {
        handleFetchError(error);
      }
    };

    fetchPaste();
  }, [id, shareId, getAuthenticatedAxios, user]);

  useEffect(() => {
    const fetchComments = async () => {
      if (paste && paste._id) {
        try {
          const axios = getAuthenticatedAxios();
          const response = await axios.get(`${API_URL}/api/comments/${paste._id}`);
          if (response.data && Array.isArray(response.data)) {
            setFullComments(response.data);
          } else {
            console.error('Unexpected comment data format:', response.data);
            setFullComments([]);
          }
        } catch (error) {
          console.error('Error fetching comments:', error);
          toast.error('Failed to fetch comments. Please try again.');
          setFullComments([]);
        }
      } else {
        setFullComments([]);
      }
    };

    fetchComments();
  }, [paste, getAuthenticatedAxios]);

  useEffect(() => {
    if (paste && paste.content) {
      if(paste.isPasswordProtected || paste.encryptionLevel !== 'none'){
        setDecodedContent(paste.content);
      } else{
        // Decode the base64 content
        const decoded = Buffer.from(paste.content, 'base64').toString('utf-8');
        setDecodedContent(decoded);
      }
    }
  }, [paste]);

  useEffect(() => {
    const detectCountry = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        setUserCountry(response.data.country_name);
      } catch (error) {
        console.error('Error detecting country:', error);
        setUserCountry('Unknown');
      }
    };

    detectCountry();
  }, []);

  useEffect(() => {
    const updateAnalytics = async () => {
      try {
        const axios = getAuthenticatedAxios();
        await axios.post(`${API_URL}/api/pastes/${paste._id}/view`, {
          country: userCountry
        });
      } catch (error) {
        console.error('Error updating paste analytics:', error);
      }
    };

    if (paste && paste._id && userCountry !== 'Unknown') {
      updateAnalytics();
    }
  }, [paste, getAuthenticatedAxios, userCountry]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(decodedContent);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
    toast.success('Copied to clipboard!');
  };

  const sharePaste = () => {
    const shareUrl = paste.shareId
      ? `${window.location.origin}/share/${paste.shareId}`
      : `${window.location.origin}/pastes/${paste._id}`;
    navigator.clipboard.writeText(shareUrl);
    toast.success('Share link copied to clipboard!');
  };

  const inviteUser = async () => {
    try {
      const axios = getAuthenticatedAxios();
      await axios.post(API_URL + `/api/pastes/${id}/invite`, { email: inviteEmail });
      toast.success('User invited successfully!');
      setInviteEmail('');
    } catch (error) {
      console.error('Error inviting user:', error);
      toast.error(error.response?.data?.message || 'Failed to invite user. Please try again.');
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const axios = getAuthenticatedAxios();
      let response;

      if (isPasswordProtected) {
        response = await axios.post(`${API_URL}/api/pastes/${id || shareId}/unlock`, { password: passwordInput });
      } else if (isPrivateEncrypted) {
        response = await axios.post(`${API_URL}/api/pastes/${id || shareId}/decrypt`, { password: passwordInput });
      }

      setPaste(response.data);
      setShowPasswordDialog(false);
      toast.success('Paste unlocked successfully');
    } catch (error) {
      console.error('Error submitting password:', error);
      toast.error(error.response?.data?.message || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
      setPasswordInput('');
    }
  };

  const handleDecryption = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const axios = getAuthenticatedAxios();
      const response = await axios.post(`${API_URL}/api/pastes/${id || shareId}/decrypt`, { password: decryptionPassword });
      setPaste(response.data);
      setDecryptedContent(response.data.content);
      setShowPasswordDialog(false);
      toast.success('Paste decrypted successfully');
    } catch (error) {
      console.error('Error decrypting paste:', error);
      toast.error(error.response?.data?.message || 'Failed to decrypt paste. Please try again.');
    } finally {
      setLoading(false);
      setDecryptionPassword('');
    }
  };

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const confirmDelete = async () => {
    try {
      const axios = getAuthenticatedAxios();
      await axios.delete(`${API_URL}/api/pastes/${id}`);
      toast.success('Paste deleted successfully');
      navigate('/dashboard');
    } catch (error) {
      console.error('Error deleting paste:', error);
      toast.error('Failed to delete paste');
    }
  };

  const handleFetchError = (error) => {
    console.error('Error fetching paste:', error);
    console.error('Error response:', error.response);
    if (error.response && error.response.status === 401) {
      if (error.response.data.message === "Authentication required for private pastes") {
        setIsPrivateEncrypted(true);
        setShowPasswordDialog(true);
      } else {
        setError('Authentication required. Please log in.');
      }
    } else if (error.response && error.response.status === 403) {
      if (error.response.data.isPasswordProtected) {
        setIsPasswordProtected(true);
        setRequiresAuth(error.response.data.requiresAuth);
        setShowPasswordDialog(true);
      } else if (error.response.data.isEncrypted) {
        setIsPrivateEncrypted(true);
        setShowPasswordDialog(true);
      } else {
        setError('You are not authorized to view this paste.');
      }
    } else if (error.response && error.response.status === 404) {
      setError('Paste not found. It may have been deleted or you may not have permission to view it.');
    } else {
      setError(error.response?.data?.message || 'An error occurred while fetching the paste.');
    }
    setLoading(false);
  };

  if (showPasswordDialog) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-800 to-gray-900 flex items-center justify-center">
        <div className="bg-gray-800 rounded-lg shadow-xl p-8 max-w-md w-full">
          <h2 className="text-2xl font-bold text-white mb-4 flex items-center">
            <FaLock className="mr-2 text-yellow-500" />
            {isPrivateEncrypted ? "Encrypted Private Paste" : "Password Protected Paste"}
          </h2>
          <p className="text-gray-300 mb-4">
            {isPrivateEncrypted 
              ? "This paste is encrypted. Please enter the decryption password."
              : "This paste is protected. Please enter the password to view its contents."}
          </p>
          <form onSubmit={isPrivateEncrypted ? handleDecryption : handlePasswordSubmit}>
            <input
              type="password"
              value={isPrivateEncrypted ? decryptionPassword : passwordInput}
              onChange={(e) => isPrivateEncrypted ? setDecryptionPassword(e.target.value) : setPasswordInput(e.target.value)}
              placeholder="Enter password"
              className="w-full px-3 py-2 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 mb-4"
            />
            <button
              type="submit"
              className="w-full bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition duration-300 flex items-center justify-center"
              disabled={loading}
            >
              {loading ? (
                <span className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white mr-2"></span>
              ) : (
                <FaUnlock className="mr-2" />
              )}
              {isPrivateEncrypted ? "Decrypt Paste" : "Unlock Paste"}
            </button>
          </form>
          {requiresAuth && !isPrivateEncrypted && (
            <p className="text-gray-300 mt-4">
              You need to log in after entering the correct password.
            </p>
          )}
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-800 to-gray-900 flex items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-800 to-gray-900 flex items-center justify-center">
        <div className="bg-gray-800 rounded-lg shadow-xl p-8 max-w-md w-full">
          <h2 className="text-2xl font-bold text-white mb-4">Error</h2>
          <p className="text-red-400 mb-6">{error}</p>
          <button
            onClick={() => navigate('/pastes')}
            className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition duration-300"
          >
            Back to Pastes
          </button>
        </div>
      </div>
    );
  }

  if (!paste) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-800 to-gray-900 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="bg-gray-800 rounded-lg shadow-xl overflow-hidden mb-6">
          <div className="px-6 py-4 bg-indigo-600 bg-opacity-75 flex justify-between items-center">
            <h2 className="text-2xl font-extrabold text-white">{paste?.title}</h2>
            {paste?.isPasswordProtected && (
              <span className="flex items-center text-yellow-300">
                <FaLock className="mr-1" />
                Password Protected
              </span>
            )}
          </div>
          <div className="px-6 py-4">
            <div className="mb-4 flex justify-between items-center flex-wrap">
              <div className="flex space-x-2">
                <button
                  onClick={copyToClipboard}
                  className="flex items-center px-3 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300 text-sm"
                >
                  <FaCopy className="mr-2" />
                  {copied ? 'Copied!' : 'Copy'}
                </button>
                <button
                  onClick={sharePaste}
                  className="flex items-center px-3 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300 text-sm"
                >
                  <FaShare className="mr-2" />
                  Share
                </button>
                {isOwner && (
                  <button
                    onClick={openDeleteDialog}
                    className="flex items-center px-3 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-300 text-sm"
                  >
                    <FaTrash className="mr-2" />
                    Delete
                  </button>
                )}
              </div>
            </div>
            {paste.visibility === 'private' && paste.user === user.id && (
              <div className="mt-4">
                <h3 className="text-white text-lg font-semibold mb-2">Invite User</h3>
                <div className="flex items-center">
                  <input
                    type="email"
                    placeholder="Enter user's email"
                    value={inviteEmail}
                    onChange={(e) => setInviteEmail(e.target.value)}
                    className="flex-grow px-3 py-2 bg-gray-700 text-white rounded-l-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                  <button
                    onClick={inviteUser}
                    className="px-4 py-2 bg-indigo-500 text-white rounded-r-md hover:bg-indigo-600 transition duration-300 flex items-center"
                  >
                    <FaUserPlus className="mr-2" />
                    Invite
                  </button>
                </div>
              </div>
            )}
            <div className="mt-4">
              <div
                ref={resizeRef}
                className="resize-y overflow-auto"
                style={{ minHeight: '200px', maxHeight: '600px', height: '400px' }}
              >
                <SyntaxHighlighter
                  language={paste.language}
                  style={vscDarkPlus}
                  className="rounded-md text-sm h-full"
                  showLineNumbers
                  wrapLines
                  wrapLongLines
                  customStyle={{ height: '100%' }}
                >
                  {isPrivateEncrypted ? decryptedContent : decodedContent}
                </SyntaxHighlighter>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center mb-6">
          <Link
            to="/pastes"
            className="bg-gray-600 text-white px-3 py-1 rounded-md hover:bg-gray-700 transition duration-300 text-sm"
          >
            Back to Pastes
          </Link>
          <button
            onClick={() => setShowComments(!showComments)}
            className="flex items-center bg-indigo-500 text-white px-3 py-1 rounded-md hover:bg-indigo-600 transition duration-300 text-sm"
          >
            {showComments ? 'Hide Comments' : 'Show Comments'}
          </button>
        </div>

        {showComments && (
          <CommentSection pasteId={paste._id} comments={fullComments} isAuthenticated={!!user} onCommentAdded={(newComment) => setFullComments([...fullComments, newComment])} />
        )}
      </div>
      <ConfirmDialog
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        onConfirm={confirmDelete}
        title="Delete Paste"
        message="Are you sure you want to delete this paste? This action cannot be undone."
      />
    </div>
  );
};

export default PasteView;