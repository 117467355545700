import React from 'react';
import { Link } from 'react-router-dom';

const CookiePolicy = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-800 to-gray-900 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-gray-700 rounded-lg shadow-xl p-8">
        <h1 className="text-3xl font-bold text-white mb-6">Cookie Policy</h1>
        <div className="space-y-4 text-gray-300">
          <p>This policy explains how PasteZen uses cookies and similar technologies.</p>
          
          <h2 className="text-xl font-semibold text-white mt-4">1. What Are Cookies</h2>
          <p>Cookies are small text files stored on your device when you visit our website.</p>
          
          <h2 className="text-xl font-semibold text-white mt-4">2. How We Use Cookies</h2>
          <p>We use cookies to improve your experience, understand site usage, and remember your preferences.</p>
          
          <h2 className="text-xl font-semibold text-white mt-4">3. Types of Cookies We Use</h2>
          <ul className="list-disc list-inside">
            <li>Essential cookies for site functionality</li>
            <li>Analytics cookies to understand usage</li>
            <li>Preference cookies to remember your settings</li>
          </ul>
          
          <h2 className="text-xl font-semibold text-white mt-4">4. Managing Cookies</h2>
          <p>You can control and/or delete cookies as you wish. You can delete all cookies on your device and set most browsers to prevent them from being placed.</p>
          
          <h2 className="text-xl font-semibold text-white mt-4">5. Changes to This Policy</h2>
          <p>We may update this policy periodically. Please review it regularly.</p>
          
          <p className="mt-6">For questions about our use of cookies, please <Link to="/contact" className="text-indigo-400 hover:text-indigo-300">contact us</Link>.</p>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;