import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Enhanced error logging
window.onerror = function(message, source, lineno, colno, error) {
  console.error('Global error:', { message, source, lineno, colno, error });
};

window.addEventListener('unhandledrejection', function(event) {
  console.error('Unhandled rejection:', event.reason);
});

// Hide ResizeObserver warnings
const consoleError = console.error;
console.error = (...args) => {
  if (
    args.length > 1 &&
    typeof args[0] === 'string' &&
    args[0].startsWith('ResizeObserver loop completed with undelivered notifications.')
  ) {
    return;
  }
  consoleError(...args);
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
